import * as React from "react";
import "./navbar-mobile.scss";
import {useState} from "react";
import {Link} from "gatsby-plugin-react-i18next";
import LogoAsset from "../../../../assets/markana-logo-sm.svg";
import BurgerAsset from "../../../../assets/burger.svg";
import CloseBurgerAsset from "../../../../assets/burger-close.svg";
import MobileMenu from "../MobileMenu/mobile-menu";

const NavbarMobile = (props: any) => {
    const [visible, setVisible] = useState(false);
    const {t} = props.i18n;

    const handleHamburgerClick = () => {
        setVisible(!visible);
    }

    const close = () => {
        setVisible(false);
    }

    return (
        <>
            <div className="navbar-mobile navbar-mobile_shadow-container">
                <div className="navbar-mobile_container">
                    <Link to={"/"} aria-label={"markana-logo"} onClick={close} className="navbar-mobile_link">
                        <LogoAsset/>
                    </Link>
                    <span>Markana</span>
                    <button onClick={handleHamburgerClick} aria-label={"menu"}>
                        {visible ? <CloseBurgerAsset/> : <BurgerAsset/>}
                    </button>
                </div>
            </div>
            {visible ? <MobileMenu closeMenu={close} i18n={props.i18n}/> : null}
        </>
    )
}

export default NavbarMobile;