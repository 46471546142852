import {Link} from "gatsby-plugin-react-i18next";
import LogoAsset from "../../../../assets/markana-logo.svg";
import * as React from "react";
import "./navbar-desktop.scss";
import PageLink from "./PageLink/page-link";

const NavbarDesktop = (props: any) => {
    const {t} = props.i18n;
    return (
            <div className="navbar-desktop_shadow-container navbar-desktop">
                <div className="navbar-desktop_container">
                    <Link to="/" className="navbar-desktop_container_link" aria-label={"markana-logo"}>
                        <LogoAsset className="navbar-desktop_logo"/>
                        <span className="navbar-desktop_container_link_markana-text">Markana</span>
                    </Link>
                    <div className="navbar-desktop_pages">
                        <PageLink to="/" content={t('navigation.aboutUs')}/>
                        <PageLink to="/how-we-work/" content={t('navigation.howWeWork')}/>
                        <PageLink to="/career/" content={t('navigation.career')}/>
                        <PageLink to="/blog/" content={t('navigation.blog')}/>
                    </div>
                    <Link className="navbar-desktop_contact" to="/#contact">{t('navigation.contact')}</Link>
                </div>
            </div>
    )
}

export default NavbarDesktop;