import * as React from "react";
import "./layout.scss";
import {PropsWithChildren} from "react";
import Footer from "../Footer/footer";

const Layout = (props: PropsWithChildren) => {
    return (
        <main className="layout">
            {props.children}
        </main>
    )
}

export default Layout;

