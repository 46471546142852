import * as React from "react";
import "./cookie-popup.scss";
import {i18n} from "i18next";
import {Link} from "gatsby-plugin-react-i18next";
import {useState} from "react";

const CookiePopup = (props: any) => {
    // Needed to render page with gatsby build
    if (typeof localStorage === "undefined") {
        return null;
    }
    
    const {t} = props.i18n;

    let [acceptedCookies, setAcceptedCookies] = useState(localStorage.getItem("acceptedCookies") ?? undefined);

    if (acceptedCookies) {
        return null;
    }

    const handleAcceptCookies = () => {
        localStorage.setItem("acceptedCookies", "true");
        setAcceptedCookies("true");
    }

    const handleDeclineCookies = () => {
        localStorage.setItem("acceptedCookies", "false");
        setAcceptedCookies("false")
    }

    return (
        <div className="cookie-popup">
            <p>
                {t('cookies.popupText')} <br/>
                <Link to={"/privacy-policy"}>{t('cookies.popupPolicyLink')}</Link>
            </p>
            <div className="cookie-popup_buttons">
                <button className="cookie-popup_btn-accept"
                        aria-label={t('cookies.accept')}
                        onClick={handleAcceptCookies}>
                    {t('cookies.accept')}
                </button>
                <button className="cookie-popup_btn-decline"
                        aria-label={t('cookies.decline')}
                        onClick={handleDeclineCookies}>
                    {t('cookies.decline')}
                </button>
            </div>

        </div>
    )
}

export default CookiePopup;