import * as React from "react"
import "./src/styles/global.scss";
import Navbar from "./src/components/Navbar/navbar";
import {GatsbyBrowser} from "gatsby";
import Layout from "./src/components/Layout/layout";
import Footer from "./src/components/Footer/footer";
import CookiePopup from "./src/components/CookiePopup/cookie-popup";


export const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({element}) =>
    <>
        <Navbar i18n={element.props.i18n}/>
        <Layout>
            {element}
        </Layout>
        <Footer/>
        <CookiePopup i18n={element.props.i18n}/>
    </>;