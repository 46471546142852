import * as React from "react"
import {PropsWithChildren} from "react"
import "./container.scss"

const Container = (props: PropsWithChildren) =>
    (
        <div className="container" >
            {props.children}
        </div>
    )

export default Container