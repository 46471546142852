import * as React from "react";
import "./navbar.scss";
import {useEffect, useState} from "react";
import NavbarMobile from "./NavbarMobile/navbar-mobile";
import NavbarDesktop from "./NavbarDesktop/navbar-desktop";
import LogoAsset from "../../../assets/markana-logo.svg";

const Navbar = (props: any) =>
    (
        <nav className="main-navbar">
            <NavbarMobile i18n={props.i18n} logoAsset={LogoAsset}/>
            <NavbarDesktop i18n={props.i18n}/>
        </nav>
    )

export default Navbar;