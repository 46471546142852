import * as React from "react";
import "./footer.scss";
import Container from "../Container/container";
import {Link} from "gatsby-plugin-react-i18next";
import LogoAsset from "../../../assets/linkedin-logo.svg";

const Footer = () => (
    <Container>
        <footer>
            <hr className="page-footer_hr"/>
            <div className="page-footer">
                <div className="page-footer_copyright"> © 2024 Markana. All rights reserved.</div>
                <Link to={"https://www.linkedin.com/company/markana"} aria-label={"markana-linkedin"}>
                    <LogoAsset className="page-footer_image"/>
                </Link>
            </div>
        </footer>
    </Container>
);


export default Footer;